export const reviews = {
  '[Dearplants] Plity': {
    xs: 4,
    paths: [
      '/static/works/plity/review1.jpg',
      '/static/works/plity/review2.jpg',
      '/static/works/plity/review3.jpg',
      '/static/works/plity/review4.jpg',
      '/static/works/plity/review5.jpg',
      '/static/works/plity/review6.jpg',
      '/static/works/plity/review7.jpg',
      '/static/works/plity/review8.jpg',
    ],
  },
}
