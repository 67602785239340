import React from 'react'
import PropTypes from 'prop-types'
import { Button, Box } from '@mui/material'
import AndroidIcon from '@mui/icons-material/Android'
import AppleIcon from '@mui/icons-material/Apple'
import { Preview } from './common/style'
import Teaser from './common/Teaser'
import Screenshots from './common/Screenshots'
import Reviews from './common/Reviews'
import Summary from './common/Summary'
import Contribution from './common/Contribution'
import { ProjectDialogContent } from '.'

export default function ProjectPlity({ color, screenshots, reviews, summary, contribution }) {
  const ANDROID_URL =
    'https://play.google.com/store/apps/details?id=com.dearplants.dearplants&hl=en_US&gl=US'
  const iOS_URL =
    'https://apps.apple.com/kr/app/%ED%94%8C%EB%A6%AC%ED%8B%B0-%EC%8B%9D%EB%AC%BC%EA%B3%BC-%EC%86%8C%ED%86%B5%ED%95%98%EA%B8%B0/id1555612272'
  return (
    <ProjectDialogContent>
      <Preview
        style={{ width: '60%' }}
        alt='plity'
        src='/static/works/plity/plity1.png'
      />
      <Preview
        style={{ width: '40%' }}
        alt='plity'
        src='/static/works/plity/plity2.jpg'
      />
      <Box
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
      >
        <Button
          size='large'
          variant='contained'
          startIcon={<AndroidIcon />}
          onClick={() => window.open(ANDROID_URL, '_blank')}
          sx={{ backgroundColor: color, marginBottom: 10, boxShadow: 0 }}
        >
          Android
        </Button>
        <Button
          size='large'
          variant='contained'
          startIcon={<AppleIcon />}
          onClick={() => window.open(iOS_URL, '_blank')}
          sx={{
            backgroundColor: color,
            marginBottom: 10,
            marginLeft: 1,
            boxShadow: 0,
          }}
        >
          iOS
        </Button>
      </Box>
      <Summary color={color} summary={summary} />
      <Contribution color={color} contribution={contribution} />
      <Teaser color={color} videoId='QejBzv7bAhw' />
      <Screenshots color={color} screenshots={screenshots} alt='plity' />
      <Reviews color={color} reviews={reviews} alt='plity' />
    </ProjectDialogContent>
  )
}

Teaser.propTypes = {
  color: PropTypes.string,
}
ProjectPlity.propTypes = {
  color: PropTypes.string,
  screenshots: PropTypes.array,
  reviews: PropTypes.array,
  summary: PropTypes.object,
  contribution: PropTypes.array
}
