import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { forwardRef } from 'react'
// @mui
import { Box } from '@mui/material'

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', meta, project, ...other }, ref) => (
  <>
    <Helmet>
      {project ? <title>{`${title}`}</title> : <title>{`${title} | Beanie`}</title>}
      {meta}
    </Helmet>

    <Box sx={{ height: 'calc(100vh - 90px)', overflow: 'auto' }} ref={ref} {...other}>
      {children}
    </Box>
  </>
))

Page.displayName = 'Page'

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
  project: PropTypes.bool
}

export default Page
