import React from 'react'
import PropTypes from 'prop-types'
import Iconify from 'components/Iconify'
import { Typography, Box } from '@mui/material'
import styled from "styled-components";
import  ReactPlayer  from  'react-player/youtube'
import { Title, Content } from './style'

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
export default function Teaser({ color, videoId }) {

  return (
    <Box sx={{ mb: 10, height: '100%' }}>
      <Title>
        <Iconify
          color={color}
          icon='akar-icons:video'
          style={{ fontSize: 23 }}
        />
        <Typography variant='h5' sx={{ ml: 1.5 }}>
          Teaser
        </Typography>
      </Title>
      <Content>
        {/* <YouTube videoId={videoId}
          opts={opts}/> */}
        <PlayerWrapper>
          <ReactPlayer
            className="react-player"
            url={"https://www.youtube.com/watch?v="+videoId}
            width="100%"
            height="100%"
            muted={true} //chrome정책으로 인해 자동 재생을 위해 mute 옵션을 true로 해주었다.
            playing={true}
            loop={false} />
        </PlayerWrapper>
      </Content>
    </Box>
  )
}

Teaser.propTypes = {
  color: PropTypes.string,
  videoId: PropTypes.string,
}
