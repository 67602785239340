import React from 'react'
import { Helmet } from 'react-helmet-async';
// routes
import Router from './routes'
// theme
import ThemeProvider from './theme'
// components
import ScrollToTop from './components/ScrollToTop'
import './App.css'

function App() {
  return (
    <>
      <Helmet>
        {/* <meta property="og:site_name" content="Jeonghye Kim (Beanie)" />
        <meta property="og:title" content="Jeonghye Kim" /> */}
        {/* <meta property="og:url" content="https://www.beanie00.com/" /> */}
        {/* <meta property="og:description" content="Beanie's homepage" /> */}
        {/* <meta property="og:image" content="static/common/beanie.png" /> */}
        {/* <meta name="description" content="Beanie's homepage" /> */}
        <meta name="keywords" content="Beanie, JeonghyeKim, CV, Homepage, LESSON, Decision ConvFormer, QCS" />
      </Helmet>
      <ThemeProvider>
        <ScrollToTop />
        <Router />
      </ThemeProvider>
    </>
  )
}

export default App
