import React from 'react'
import { Box, Button } from '@mui/material'
import PropTypes from 'prop-types'
import LanguageIcon from '@mui/icons-material/Language'
import ExtensionIcon from '@mui/icons-material/Extension'
import AndroidIcon from '@mui/icons-material/Android'
import { Preview } from './common/style'
import Teaser from './common/Teaser'
import Screenshots from './common/Screenshots'
import Summary from './common/Summary'
import Contribution from './common/Contribution'
import { ProjectDialogContent } from '.'

export default function ProjectCatty({ color, screenshots, summary, contribution }) {
  const WEB_URL = 'https://www.cattynote.com'
  const EXTENSION_URL =
    'https://chrome.google.com/webstore/detail/catty-web-clipper/kjajbpgmgnojjjjihidkagemppicbjpg'
  const ANDROID_URL =
    'https://play.google.com/store/apps/details?id=com.pigbean.catty'

  return (
    <ProjectDialogContent>
      <Preview alt='growup' src='/static/works/catty.png' />
      <Box
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
      >
        <Button
          size='large'
          variant='contained'
          startIcon={<LanguageIcon />}
          onClick={() => window.open(WEB_URL, '_blank')}
          sx={{ backgroundColor: color, marginBottom: 10, boxShadow: 0 }}
        >
          Web
        </Button>
        <Button
          size='large'
          variant='contained'
          startIcon={<ExtensionIcon />}
          onClick={() => window.open(EXTENSION_URL, '_blank')}
          sx={{
            backgroundColor: color,
            marginLeft: 1,
            marginBottom: 10,
            boxShadow: 0,
          }}
        >
          Extension
        </Button>
        <Button
          size='large'
          variant='contained'
          startIcon={<AndroidIcon />}
          onClick={() => window.open(ANDROID_URL, '_blank')}
          sx={{
            backgroundColor: color,
            marginLeft: 1,
            marginBottom: 10,
            boxShadow: 0,
          }}
        >
          Android
        </Button>
      </Box>
      <Summary color={color} summary={summary} />
      <Contribution color={color} contribution={contribution} />
      <Teaser color={color} videoId='n-lB4uCQyGQ' />
      <Screenshots color={color} screenshots={screenshots} alt='catty' />
    </ProjectDialogContent>
  )
}

Teaser.propTypes = {
  color: PropTypes.string,
}

ProjectCatty.propTypes = {
  color: PropTypes.string,
  screenshots: PropTypes.array,
  summary: PropTypes.object,
  contribution: PropTypes.array
}
