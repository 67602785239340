export const screenshots = {
  '[Dearplants] Plity': {
    xs: 4,
    paths: [
      '/static/works/plity/plity3.png',
      '/static/works/plity/plity4.png',
      '/static/works/plity/plity5.png',
      '/static/works/plity/plity6.png',
      '/static/works/plity/plity7.png',
    ],
  },
  'Catty - Knowledge Eating Cat': {
    xs: 12,
    paths: [
      '/static/works/catty/catty1.png',
      '/static/works/catty/catty2.png',
      '/static/works/catty/catty3.png',
      '/static/works/catty/catty4.png',
      '/static/works/catty/catty5.png',
    ],
  },
  'Grow up': {
    xs: 4,
    paths: [
      '/static/works/growup/growup1.png',
      '/static/works/growup/growup2.png',
      '/static/works/growup/growup3.png',
      '/static/works/growup/growup4.png',
      '/static/works/growup/growup5.png',
      '/static/works/growup/growup6.png',
    ],
  },
  '[ARTWA] Admin page': {
    xs: 6,
    paths: [
      '/static/works/artwa/artwa1.png',
      '/static/works/artwa/artwa2.png',
      '/static/works/artwa/artwa3.png',
      '/static/works/artwa/artwa4.png',
    ],
  },
  '[Ulimbridge] Psychology based dating app': {
    xs: 4,
    paths: [
      '/static/works/serendipity/serendipity1.png',
      '/static/works/serendipity/serendipity2.png',
      '/static/works/serendipity/serendipity3.png',
      '/static/works/serendipity/serendipity4.png',
      '/static/works/serendipity/serendipity5.png',
      '/static/works/serendipity/serendipity6.png',
    ],
  },
  'One-stop servie for childcare, UHA': {
    xs: 4,
    paths: [
      '/static/works/uha/uha1.png',
      '/static/works/uha/uha2.png',
      '/static/works/uha/uha3.png',
      '/static/works/uha/uha4.png',
      '/static/works/uha/uha5.png',
      '/static/works/uha/uha6.png',
      '/static/works/uha/uha7.png',
    ],
  },
  '[Kai-i Company] Lawlogging': {
    xs: 12,
    paths: [
      '/static/works/lawlogging/lawlogging1.png',
      '/static/works/lawlogging/lawlogging2.png',
      '/static/works/lawlogging/lawlogging3.png',
      '/static/works/lawlogging/lawlogging4.png',
      '/static/works/lawlogging/lawlogging5.png',
      '/static/works/lawlogging/lawlogging6.png',
      '/static/works/lawlogging/lawlogging7.png',
    ],
  },
  '[HanwooLab] Hair designer app': {
    xs: 4,
    paths: [
      '/static/works/hairapp/hairapp1.png',
      '/static/works/hairapp/hairapp2.png',
      '/static/works/hairapp/hairapp3.png',
      '/static/works/hairapp/hairapp4.png',
    ],
  }
}
