import React from 'react'
import { Box, Typography } from '@mui/material'

export default function Education() {

  return (
    <Box>
      <Box sx={{pl: 0.5}}>
        <Typography
          sx={{
            fontSize: '0.9rem',
            color: 'grey.500',
            fontWeight: 'bold',
          }}
        >
          2024.03~
        </Typography>
        <Typography sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
          ⚡ Ph.D. Candidate in Electrical Engineering, KAIST
        </Typography>
        <Typography sx={{pl: 0.5}}>
          * advised by Prof. <a href='https://sites.google.com/view/youngchulsung' target='_blank' rel="noreferrer">Youngchul Sung</a>
        </Typography>
      </Box>
      <Box sx={{mt: 2, pl: 0.5}}>
        <Typography
          sx={{
            fontSize: '0.9rem',
            color: 'grey.500',
            fontWeight: 'bold',
          }}
        >
          2022.03-2024.02
        </Typography>
        <Typography sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
          ⚡ M.S. in Electrical Engineering, KAIST
        </Typography>
        <Typography sx={{pl: 0.5}}>
          * advised by Prof. <a href='https://sites.google.com/view/youngchulsung' target='_blank' rel="noreferrer">Youngchul Sung</a>
        </Typography>
      </Box>
      <Box sx={{ mt: 2, pl: 0.5 }}>
        <Typography
          sx={{
            fontSize: '0.9rem',
            color: 'grey.500',
            fontWeight: 'bold',
          }}
        >
          2015.03-2022.02
        </Typography>
        <Typography sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
          👩‍💻 B.S. in School of Computing, KAIST (Cum laude)
        </Typography>
      </Box>
    </Box>
  )
}