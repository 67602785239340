// import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

//
import React from 'react'
import ReactDOM from "react-dom/client";
import App from './App'
import * as serviceWorker from './serviceWorker'
import reportWebVitals from './reportWebVitals'

const container = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(container);

if (container.hasChildNodes()) {
  ReactDOM.hydrateRoot(
    container,
    <React.StrictMode>
        <HelmetProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <App />
        </BrowserRouter>
      </HelmetProvider>,
    </React.StrictMode>
  );
} else {
  root.render(
    <React.StrictMode>
        <HelmetProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <App />
        </BrowserRouter>
      </HelmetProvider>,
    </React.StrictMode>
  );
}
root.render(
  <React.StrictMode>
      <HelmetProvider>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </HelmetProvider>,
  </React.StrictMode>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister()

reportWebVitals();