// @mui
import { alpha, styled as Mstyled } from '@mui/material/styles'
import styled from "styled-components";
import {
  Grid,
  Container,
  Card,
  Chip as MuiChip,
  Box,
  Typography,
  CardMedia,
} from '@mui/material'
import ReactPlayer  from  'react-player/youtube'
// components
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ProjectDetail from './ProjectDetail'
import Page from '../../components/Page'
import works from '../../data/project-list.json'

// ----------------------------------------------------------------------

const Category = Mstyled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginTop: '80px',
  marginBottom: '15px',
  fontWeight: 700,
  fontSize: '1.8rem',
}))

const ProjectTypography = Mstyled(Typography)(({ theme }) => ({
  marginTop: '80px',
  marginBottom: '24px',
  [theme.breakpoints.down('md')]: {
    marginTop: '40px',
  },
}))

// ----------------------------------------------------------------------

const SelectedTitle = Mstyled(Typography)(({ onClick }) => ({
  marginTop: '5px',
  marginBottom: '5px',
  fontWeight: 600,
  fontSize: '1.2rem',
  cursor: 'pointer',
  textDecoration: onClick && 'underline'
}))

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
  border-radius: 5px;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px;
  }
`;

const PlayerBox = Mstyled(Box)(({ theme }) => ({
  width: '35%',
  [theme.breakpoints.down('md')]: {
    width: '70%',
    marginBottom: '10px'
  },
}))

const SelectedProjectContent = Mstyled(Box)(({ theme }) => ({
  marginLeft: '40px',
  width: '70%',
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
    width: '100%'
  },
}))

const SelectedProjectBox = Mstyled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}))


// ----------------------------------------------------------------------

const Chip = Mstyled(MuiChip)(({ theme, selected }) => ({
  marginLeft: '10px',
  cursor: 'pointer',
  background: selected? alpha(theme.palette.primary.lighter, 0.3): 'white',
  color: selected? theme.palette.grey[900] : theme.palette.grey[500],
  border: selected ? `2px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.grey[600]}`,
  '&:hover': {
    background: alpha(theme.palette.primary.lighter, 0.2),
    color: theme.palette.grey[700],
  }
}))

// ----------------------------------------------------------------------

const ContentStyle = Mstyled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  paddingTop: '30px',
  [theme.breakpoints.down('md')]: {
    padding: '0px',
    paddingTop: '10px'
  },
}))

const FilterCategory = ({ title, options, selectedOption, setSelectedOption }) => (
  <Box
    sx={{
      mt: title=='Type' ? 3 : 0.5,
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <Typography sx={{ color: 'gray', fontWeight: 'bold', mr: 2 }}>
      {title}
    </Typography>
    <Box sx={{ textAlign: 'center', width: '100%', display: 'flex', flexWrap: 'wrap' }}>
      {options.map((option) => (
        <Chip
          key={option.value}
          label={option.label}
          sx={{mb: 1}}
          selected={selectedOption === option.value}
          onClick={() => setSelectedOption(option.value)}
        />
      ))}
    </Box>
  </Box>
);

FilterCategory.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.any.isRequired,
  selectedOption: PropTypes.string.isRequired,
  setSelectedOption: PropTypes.func.isRequired
}


const ProjectCard = Mstyled(Card)(({ theme }) => ({
  borderRadius: '10px',
  [theme.breakpoints.down('md')]: {
    borderRadius: '8px'
  },
}))

// ----------------------------------------------------------------------

export default function Project() {
  const [selectedWorks, setSelectedWorks] = useState(works)
  const [detailOpen, setDetailOpen] = useState(false)
  const [detailWorkTitle, setDetailWorkTitle] = useState('')
  const [type, setType] = useState('all')
  const [domain, setDomain] = useState('all')

  useEffect(() => {
    setSelectedWorks(
      works.filter(
        (w) =>
          (w.type === type || type === 'all') &&
          (w.domain.includes(domain) || domain === 'all'),
      ),
    )
  }, [domain, type])

  function openDetail(workTitle) {
    setDetailWorkTitle(workTitle)
    setDetailOpen(true)
  }

  const projects = [
    {
      id: 'server_monitor',
      imageUrl: 'static/works/server_monitor.png',
      title: 'Laboratory server monitoring system',
      description: '2022 Fall',
      detail: 'I built a monitoring system to easily track the usage status of the CPU and GPU usage on multiple lab servers. This system connects to the servers every hour via a cron job to gather information which is then stored in DB. The information can then be accessed and displayed through a web.',
      videoUrl: '',
    },
    {
      id: 'catty_knowledge_eating_cat',
      imageUrl: '',
      videoUrl: 'https://www.youtube.com/watch?v=n-lB4uCQyGQ',
      title: 'Catty - Knowledge Eating Cat',
      description: '2021 Fall',
      detail: 'Collect useful contents among numerous Internet resouces and expand your knowledge by organizing them in your own note',
    },
    {
      id: 'dearplants_plity',
      imageUrl: '',
      videoUrl: 'https://www.youtube.com/watch?v=QejBzv7bAhw',
      title: '[Dearplants] Plity',
      description: '2022-2024',
      detail: '"PLITY" is an AI chatbot service that assists in plant management through communication with plants. The mood and condition of the plants are analyzed and expressed through various characters, allowing users to have diverse conversations with the plants and learn appropriate plant care techniques.',
    },
  ];

  const typeOptions = [
    { value: 'all', label: 'All type' },
    { value: 'self developed', label: 'Self developed' },
    { value: 'outsourced', label: 'Outsourced' },
  ];

  const domainOptions = [
    { value: 'all', label: 'All domain' },
    { value: 'dev', label: 'Front-end Dev' },
    { value: 'planning', label: 'Product Planning' },
    { value: 'uiux', label: 'UIUX Design' },
  ];

  return (
    <Page title='Dev Projects'>
      <ProjectDetail
        open={detailOpen}
        setOpen={setDetailOpen}
        title={detailWorkTitle}
      />
      <Container maxWidth='md' >
        <ProjectTypography variant='h2'>
        🌟 Dev Projects
        </ProjectTypography>
        <Typography>I have been through multiple complete cycles of planning, designing, developing, and operating projects.
          While I may have varying levels of expertise, I am capable of creating web applications, native mobile apps, and cross-platform mobile apps.
          I make a conscious effort to produce code that is easy to read.</Typography>
        <Category sx={{ mt: 8, mb: 2 }}>Selected Projects</Category>
        <Box>
          {projects.map((project, index) => (
            <SelectedProjectBox key={project.id} sx={{ mt: index === 0 ? 2 : 5 }}>
              <PlayerBox sx={{ backgroundColor: '#e9e9e9', borderRadius: '8px' }}>
                {project.imageUrl ? (
                  <img width="100%" src={project.imageUrl} alt={project.title} />
                ) : (
                  <PlayerWrapper>
                    <ReactPlayer
                      className="react-player"
                      url={project.videoUrl}
                      width="100%"
                      height="100%"
                      muted={true}
                      controls
                      playing={false}
                      loop={false}
                    />
                  </PlayerWrapper>
                )}
              </PlayerBox>
              <SelectedProjectContent>
                <Typography sx={{ fontSize: '0.9rem', color: 'grey.500', fontWeight: 'bold' }}>
                  {project.description}
                </Typography>
                <SelectedTitle onClick={() => openDetail(project.title)}>{project.title}</SelectedTitle>
                {project.detail}
              </SelectedProjectContent>
            </SelectedProjectBox>
          ))}
        </Box>

        <Category sx={{ mt: 12 }}>All Projects</Category>
        <FilterCategory title="Type" options={typeOptions} selectedOption={type} setSelectedOption={setType} />
        <FilterCategory title="Domain" options={domainOptions} selectedOption={domain} setSelectedOption={setDomain} />


        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center', mb: 8 }}>
          <Grid
            container
            alignItems='stretch'
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {selectedWorks.map((w, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <div
                  onClick={() => openDetail(w.title)}
                  style={{ cursor: 'pointer' }}
                >
                  <ProjectCard>
                    <CardMedia component='img' image={w.image} alt='preview' />
                  </ProjectCard>
                  <Typography sx={{ mt: 1 }}>{w.title}</Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </ContentStyle>
      </Container>
    </Page>
  )
}