import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent } from '@mui/material'
import { styled } from '@mui/material/styles'
import ProjectCatty from './ProjectCatty'
import ProjectPlity from './ProjectPlity'
import ProjectGrowup from './ProjectGrowup'
import ProjectUha from './ProjectUha'
import ProjectSerendipity from './ProjectSerendipity'
import { colors } from './config/colors'
import { screenshots } from './config/screenshots'
import { reviews } from './config/reviews'
import { summary } from './config/summary'
import { contribution } from './config/contribution'
import ProjectLawLogging from './ProjectLawLogging'

export const ProjectDialogContent = styled(DialogContent)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    paddingRight: '50px',
    paddingLeft: '50px',
    paddingTop: '20px',
    paddingBottom: '20px'
  },
}))

export default function ProjectDetail({ open, setOpen, title }) {
  function SelectedDetail({ title }) {
    switch (title) {
      case '[Dearplants] Plity':
        return (
          <ProjectPlity
            color={colors[title]}
            screenshots={screenshots[title]}
            reviews={reviews[title]}
            summary={summary[title]}
            contribution={contribution[title]}
          />
        )
      case 'Catty - Knowledge Eating Cat':
        return (
          <ProjectCatty
            color={colors[title]}
            screenshots={screenshots[title]}
            summary={summary[title]}
            contribution={contribution[title]}
          />
        )
      case 'Grow up':
        return (
          <ProjectGrowup
            color={colors[title]}
            screenshots={screenshots[title]}
            summary={summary[title]}
            contribution={contribution[title]}
          />
        )
      case '[Ulimbridge] Psychology based dating app':
        return (
          <ProjectSerendipity
            color={colors[title]}
            screenshots={screenshots[title]}
            summary={summary[title]}
            contribution={contribution[title]}
          />
        )
      case 'One-stop servie for childcare, UHA':
        return (
          <ProjectUha
            color={colors[title]}
            screenshots={screenshots[title]}
            summary={summary[title]}
            contribution={contribution[title]}
          />
        )
      case '[Kai-i Company] Lawlogging':
        return (
          <ProjectLawLogging
            color={colors[title]}
            screenshots={screenshots[title]}
            summary={summary[title]}
            contribution={contribution[title]}
          />
        )
      default:
        break
    }
  }

  return (
    <Dialog
      fullWidth='lg'
      maxWidth='lg'
      open={open}
      onClose={() => setOpen(false)}
    >
      <SelectedDetail title={title} />
    </Dialog>
  )
}

ProjectDetail.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.function,
  title: PropTypes.string,
}
