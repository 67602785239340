import React from 'react'
import PropTypes from 'prop-types'
import Iconify from 'components/Iconify'
import { Box, Grid, Typography } from '@mui/material'
import { Title, Content } from './style'

export default function Screenshots({ color, screenshots, alt }) {
  return (
    <Box sx={{ mb: 10 }}>
      <Title>
        <Iconify
          color={color}
          icon='akar-icons:camera'
          style={{ fontSize: 23 }}
        />
        <Typography variant='h5' sx={{ ml: 1.5 }}>
          Screenshots
        </Typography>
      </Title>
      <Content>
        <Grid container spacing={2}>
          {screenshots.paths.map((path, index) => {
            return (
              <Grid key={index} item xs={screenshots.xs}>
                <img
                  style={{ objectFit: 'contain', width: '100%' }}
                  alt={alt}
                  src={path}
                />
              </Grid>
            )
          })}
        </Grid>
      </Content>
    </Box>
  )
}

Screenshots.propTypes = {
  color: PropTypes.string,
  screenshots: PropTypes.array,
  alt: PropTypes.string,
}
