import React from 'react'
import { Button, Box } from '@mui/material'
import PropTypes from 'prop-types'
import LanguageIcon from '@mui/icons-material/Language'
import { Preview } from './common/style'
import Screenshots from './common/Screenshots'
import Summary from './common/Summary'
import Contribution from './common/Contribution'
import { ProjectDialogContent } from '.'

export default function ProjectLawLogging({ color, screenshots, summary, contribution }) {
  const WEB_URL = 'https://www.lawlogging.kr'

  return (
    <ProjectDialogContent>
      <Preview alt='growup' src='/static/works/lawlogging.png' />
      <Box
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
      >
        <Button
          size='large'
          variant='contained'
          startIcon={<LanguageIcon />}
          onClick={() => window.open(WEB_URL, '_blank')}
          sx={{ backgroundColor: color, marginBottom: 10, boxShadow: 0 }}
        >
          Web
        </Button>
      </Box>
      <Summary color={color} summary={summary} />
      <Contribution color={color} contribution={contribution} />
      <Screenshots color={color} screenshots={screenshots} alt='lawlogging' />
    </ProjectDialogContent>
  )
}

ProjectLawLogging.propTypes = {
  color: PropTypes.string,
  screenshots: PropTypes.array,
  summary: PropTypes.object,
  contribution: PropTypes.array
}
