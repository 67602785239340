import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Preview = styled('img')(() => ({
  objectFit: 'contain',
  width: '100%',
  marginBottom: '20px',
}))

export const Title = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '5px',
}))

export const Content = styled(Box)(() => ({
  paddingTop: '10px',
  paddingBottom: '10px',
}))
