import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { forwardRef } from 'react'
// @mui
import { Box } from '@mui/material'

// ----------------------------------------------------------------------

const ProjectPage = forwardRef(({ children, title='', description='', keywords='', imgsrc='', url='' }, ref) => (
  <>
    {/* <Helmet>
      {<title>{`${title}`}</title>}
      {meta}
    </Helmet> */}
    <Helmet>
      <title>{title}</title>

      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imgsrc} />
      <meta property="og:url" content={url} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imgsrc} />

      <link rel="canonical" href={url} />
    </Helmet>

    <Box sx={{ height: 'calc(100vh - 40px)'}} ref={ref}>
      {children}
    </Box>
  </>
))

ProjectPage.displayName = 'Page'

ProjectPage.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  imgsrc: PropTypes.string,
  url: PropTypes.string,
  project: PropTypes.bool
}

export default ProjectPage
