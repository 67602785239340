export const contribution = {
  '[Dearplants] Plity': [
    'Full project planning, project management,',
    'Android app solo development, iOS app development'
  ]
  ,
  'Catty - Knowledge Eating Cat': [
    'One-person planning, design and development as side project'
  ],
  'Grow up': [
    'Full project planning, project management, Hybrid app (Flutter) development',
  ],
  '[Ulimbridge] Psychology based dating app': [
    'Android app solo development'
  ],
  '[ARTWA] Admin page': [
    'Web app solo development'
  ],
  'One-stop servie for childcare, UHA': [
    'Planning, UIUX design, React Native app development'
  ],
  '[Kai-i Company] Lawlogging': [
    'Planning, UIUX design, webapp frontend solo development'
  ],
  '[HanwooLab] Hair designer app': [
    'Admin web page, hybrid app solo development'
  ],
}
