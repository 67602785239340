import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import Iconify from 'components/Iconify'
import { Typography, Box } from '@mui/material'
import { Title, Content } from './style'

const Icon = styled(Iconify)(({ theme }) => ({
  width: '16px',
  fontSize: '1.1rem',
  [theme.breakpoints.down('md')]: {
    width: '13px',
  },
}))

const IconWrapper = styled('div')(({ theme }) => ({
  width: '16px',
  [theme.breakpoints.down('md')]: {
    width: '13px',
  },
}))


export default function Contribution({ color, contribution }) {
  return (
    <Box sx={{ mb: 10 }}>
      <Title>
        <Iconify
          color={color}
          icon='akar-icons:face-very-happy'
          style={{ fontSize: 23 }}
        />
        <Typography variant='h5' sx={{ ml: 1.5 }}>
          Contribution
        </Typography>
      </Title>
      <Content>
        {contribution.map((content, index) => {
          return (
            <Box
              key={index}
              sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
            >
              <IconWrapper>
                <Icon color={color} icon='akar-icons:square-fill' />
              </IconWrapper>
              <Typography sx={{ fontSize: '1.1rem', ml: 3 }}>
                {content}
              </Typography>
            </Box>
          )
        })}
      </Content>
    </Box>
  )
}

Contribution.propTypes = {
  color: PropTypes.string,
  contribution: PropTypes.array,
}
