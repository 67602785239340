import React from 'react'
import { Button, Box } from '@mui/material'
import PropTypes from 'prop-types'
import GitHubIcon from '@mui/icons-material/GitHub'
import { Preview } from './common/style'
import Screenshots from './common/Screenshots'
import Summary from './common/Summary'
import Contribution from './common/Contribution'
import { ProjectDialogContent } from '.'

export default function ProjectGrowup({ color, screenshots, summary, contribution }) {
  const GITHUB_URL = 'https://github.com/beanie00/2021-Growup-Flutter'

  return (
    <ProjectDialogContent>
      <Preview alt='growup' src='/static/works/growup/growup_preview.png' />
      <Box
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
      >
        <Button
          size='large'
          variant='contained'
          startIcon={<GitHubIcon />}
          onClick={() => window.open(GITHUB_URL, '_blank')}
          sx={{ backgroundColor: color, marginBottom: 10, boxShadow: 0 }}
        >
          View on Github
        </Button>
      </Box>
      <Summary color={color} summary={summary} />
      <Contribution color={color} contribution={contribution} />
      <Screenshots color={color} screenshots={screenshots} alt='growup' />
    </ProjectDialogContent>
  )
}

ProjectGrowup.propTypes = {
  color: PropTypes.string,
  screenshots: PropTypes.array,
  summary: PropTypes.object,
  contribution: PropTypes.array
}
