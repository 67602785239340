export const colors = {
  '[Dearplants] Plity': '#114125',
  'Catty - Knowledge Eating Cat': '#333333',
  'Grow up': '#430858',
  '[ARTWA] Admin page': '#104b29',
  '[Ulimbridge] Psychology based dating app': '#623cea',
  'One-stop servie for childcare, UHA': '#cb5600',
  '[Kai-i Company] Lawlogging': '#2b6ad0',
  '[HanwooLab] Hair designer app': '#eac600'
}
