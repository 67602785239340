export const summary = {
  '[Dearplants] Plity': {
    'Project name': 'Plity',
    'Project type': 'Self developed',
    'Project duration': '2020.07 ~ 2021.01',
    'User count': '10k',
    'Frontend tech stack': {
      'Android native, ': 'logos:android-vertical',
      'iOS native': 'logos:swift',
    },
    'Backend tech stack': {
      Flask: 'logos:flask',
    },
  },
  'Catty - Knowledge Eating Cat': {
    'Project name': 'Catty',
    'Project type': 'Self developed',
    'Project duration': '2021.10 ~ present',
    'Frontend tech stack': {
      'React.js, ': 'logos:react',
      'Next.js, ': 'cib:next-js',
      'Android native': 'logos:android-vertical',
    },
    'Backend tech stack': {
      'Node.js, ': 'logos:nodejs',
      Firestore: 'logos:firebase',
    },
  },
  'Grow up': {
    'Project name': 'Grow up',
    'Project type': 'Self developed',
    'Project duration': '2021.09 ~ 2021.10',
    'Frontend tech stack': {
      Flutter: 'logos:flutter',
    },
    'Backend tech stack': {
      'AWS-Lambda ': 'logos:aws-lambda',
    },
  },
  '[Ulimbridge] Psychology based dating app': {
    'Project name': 'Serendipity',
    'Project type': 'Outsourced',
    'Project duration': '2019.12 ~ 2020.03',
    'Frontend tech stack': {
      'Android native': 'logos:android-vertical',
    },
  },
  // '[ARTWA] Admin page': {
  //   'Project name': 'Artwa admin page',
  //   'Project type': 'Outsourced',
  //   'Project duration': '2021.09',
  //   'Frontend tech stack': {
  //     'React.js': 'logos:react',
  //   },
  //   'Backend tech stack': {
  //     'AWS-Lambda ': 'logos:aws-lambda',
  //   },
  // },
  'One-stop servie for childcare, UHA': {
    'Project name': 'UHA',
    'Project type': 'Outsourced',
    'Project duration': '2021.10 ~ 2021.12',
    'Frontend tech stack': {
      'React-native': 'logos:react',
    },
    'Backend tech stack': {
      'AWS-Lambda ': 'logos:aws-lambda',
    },
  },
  '[Kai-i Company] Lawlogging': {
    'Project name': 'Lawlogging',
    'Project type': 'Outsourced',
    'Project duration': '2021.10 ~ 2022.03',
    'Frontend tech stack': {
      'React-native': 'logos:react',
    },
    'Backend tech stack': {
      'AWS-Lambda ': 'logos:aws-lambda',
    },
  },
  '[HanwooLab] Hair designer app': {
    'Project name': 'Hair designer app',
    'Project type': 'Outsourced',
    'Project duration': '2021.10 ~ 2021.11',
    'Frontend tech stack': {
      'Vue.js, ': 'logos:vue',
      'Capacitor': 'logos:capacitorjs-icon'
    },
    'Backend tech stack': {
      'AWS-Lambda ': 'logos:aws-lambda',
    },
  },
}
